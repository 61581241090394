$(function () {
  AOS.init();
  
// Мобильное меню бургер
function burgerMenu() {
    const burger = document.querySelector('.burger')
    const menu = document.querySelector('.menu')
    const body = document.querySelector('body')
    burger.addEventListener('click', () => {
      if (!menu.classList.contains('active')) {
        menu.classList.add('active')
        burger.classList.add('active-burger')
        body.classList.add('locked')
      } else {
        menu.classList.remove('active')
        burger.classList.remove('active-burger')
        body.classList.remove('locked')
      }
    })
    // Вот тут мы ставим брейкпоинт навбара
    window.addEventListener('resize', () => {
      if (window.innerWidth > 991.98) {
        menu.classList.remove('active')
        burger.classList.remove('active-burger')
        body.classList.remove('locked')
      }
    })
  }
  burgerMenu()
  
  // Вызываем эту функцию, если нам нужно зафиксировать меню при скролле.
  function fixedNav() {
    const nav = document.querySelector('nav');
    const navbar = document.querySelector('.navbar')
  
    // тут указываем в пикселях, сколько нужно проскроллить что бы наше меню стало фиксированным
    const breakpoint = 1
    if (window.scrollY >= breakpoint) {
      nav.classList.add('nav_backdrop')
      navbar.classList.add('navbar__scroll')
    } else {
      nav.classList.remove('nav_backdrop')
      navbar.classList.remove('navbar__scroll')
    }
  }
  window.addEventListener('scroll', fixedNav)
  
  fixedNav()

//Mask telephone
$('.phone').mask('+7 999 999-99-99');

//Плавное появление страницы
setTimeout(function(){
	$('body').addClass('body__visible');
}, 300);

//Плавный скролл
$('a[href^="#"').on('click', function() {
  let href = $(this).attr('href');
  if ($(href).length) {
    $('.menu.active').removeClass('active');
    $('.burger.active-burger').removeClass('active-burger');
    $('.body__hide.body__visible.locked').removeClass('locked');
    $('html, body').animate({
      scrollTop: $(href).offset().top - 75
  });
  } else return false
});

// $('.inn').mask('9999999999');

// // Функция для проверки правильности ИНН


// function is_valid_inn(i) {
//     if ( i.match(/\D/) || i < 10) return false;

//     var inn = i.match(/(\d)/g);

// //ИНН юр.лица
//     if ( inn.length == 10 )
//     {
//         return inn[9] == String(((
//                     2*inn[0] + 4*inn[1] + 10*inn[2] +
//                     3*inn[3] + 5*inn[4] +  9*inn[5] +
//                     4*inn[6] + 6*inn[7] +  8*inn[8]
//                 ) % 11) % 10);
//     }

//     // //ИНН физ лица
//     // else if ( inn.length == 12 )
//     // {
//     //     return inn[10] == String(((
//     //                 7*inn[0] + 2*inn[1] + 4*inn[2] +
//     //                 10*inn[3] + 3*inn[4] + 5*inn[5] +
//     //                 9*inn[6] + 4*inn[7] + 6*inn[8] +
//     //                 8*inn[9]
//     //             ) % 11) % 10) && inn[11] == String(((
//     //                 3*inn[0] +  7*inn[1] + 2*inn[2] +
//     //                 4*inn[3] + 10*inn[4] + 3*inn[5] +
//     //                 5*inn[6] +  9*inn[7] + 4*inn[8] +
//     //                 6*inn[9] +  8*inn[10]
//     //             ) % 11) % 10);
//     // }
//     return false;
// }

// $('#reginn').on('input', function() {
//   $this = $(this);
//   let inn = $(this).val();
  
//   if (is_valid_inn(inn)) {
//     $(this).removeClass('field__control_error');
//     $(this).addClass('field__control_valid');
//   }
//   else if ($(this).val() == '') {
//     $(this).removeClass('field__control_error');
//     $(this).removeClass('field__control_valid');
//   }
//   else {
//     $(this).addClass('field__control_error');
//     $(this).removeClass('field__control_valid');
//   }
// });

//Свой метод для валидатора
// $.validator.addMethod ('validINN', 
// function(value) {
//   if ( value.match(/\D/) || value < 10) return false;

//     var inn = value.match(/(\d)/g);

// //ИНН юр.лица
//     if ( inn.length == 10 )
//     {
//         return inn[9] == String(((
//                     2*inn[0] + 4*inn[1] + 10*inn[2] +
//                     3*inn[3] + 5*inn[4] +  9*inn[5] +
//                     4*inn[6] + 6*inn[7] +  8*inn[8]
//                 ) % 11) % 10);
//     } 
// });


// $('#reginn').on('input', function() {
//   $this = $(this);
//   let inn = $(this).val();
//   console.log(is_valid_inn(inn));
//   if (is_valid_inn(inn)) {
//     $(this).removeClass('field__control_error');
//     $(this).addClass('field__control_valid');

//     // $.ajax({
//     //   url: `https://htmlweb.ru/json/service/org?inn=${inn}`,
//     //   method: 'GET',
//     //   dataType: 'json',
//     //   success: function(data){
//     //     let organization = data.name;
//     //     let kpp = data.kpp;
//     //     $('#kpp').val(kpp);
//     //     $('#organization').val(organization);
//     //   }
//     // });
//   }
//   else if ($(this).val() == '') {
//     $(this).removeClass('field__control_error');
//     $(this).removeClass('field__control_valid');
//   }
//   else {
//     $(this).addClass('field__control_error');
//     $(this).removeClass('field__control_valid');
//   }
// })


// Validate field
$("#consultation__form").validate({
  rules: {
    email: {
      required: true,
      email: true,
      maxlength: 30
    },

    phone: {
      required: true,
      minlength: 16
    },

    organization: {
      required: true,
      maxlength: 20
    },

    region: {
      required: true,
      maxlength: 20
    },

},
errorPlacement: function(error, element) {   },
errorClass : 'field__control_error',
validClass : 'field__control_valid',
submitHandler: function(form, event) {event.preventDefault()
  let $sendValue = $('#consultation__form').serialize();
  let buttonSend = $('#consultation .button-primary.button-primary_center');
  $.ajax({
    url: './conf/telegram.php',
    method: 'POST',
    dataType: 'html',
    data: $sendValue,
    beforeSend: function(){
      buttonSend.html("<i class='fa fa-spinner fa-2x spinner aria-hidden='true'></i>").attr("disabled", true);
    },
    success: function(data){
      if (data === 'ok') {
        buttonSend.text('Отправить запрос')
        $('#consultation__form').trigger('reset');
        $('#consultation__form input').map((index,element) => {
          $(element).removeClass('field__control_error');
          $(element).removeClass('field__control_valid');
        });
        alert('Заявка успешно отправлена.');
        $.fancybox.close();
      } else 
      alert('Произошла ошибка, проверьте корректность ввода данных.');
    }
  });
}
});

//Выбор профиля

$('input[name="person"]').on('click', function() {
  if ($('#boss').is(':checked')) {
    $('.about-person_img').attr("src","../img/photos/boss.png").show(300);
    $('.about-person_info').text('Алабужев Максим');
  }
  
  if ($('#it-boss').is(':checked')) {
    $('.about-person_img').attr("src","../img/photos/itboss.png").show(300);
    $('.about-person_info').text('Давыденко Олеся').show(300);
  }
  
  if ($('#integra').is(':checked')) {
    $('.about-person_img').attr("src","../img/photos/integra.jpg").show(300);
    $('.about-person_info').text('Катиба Елена');
  }

  if ($('#lawyer').is(':checked')) {
    $('.about-person_img').attr("src","../img/photos/lawyer.jpeg").show(300);
    $('.about-person_info').text('Абросимов Дмитрий');
  }
});

//Выбор по умолчанию карточки
$("#boss").trigger('click');


//dark theme
$('.navbar__switch').on('click', function() {

  $('body').toggleClass("day", 300);

  let img = ($('.navbar__switch-img').attr('src') === './img/day.png') ? './img/moon.png' : './img/day.png';
  $('.navbar__switch-img').attr('src', img);

  let color = ($('.menu__item').css('color') === 'rgb(255, 255, 255)') ? 'rgba(0, 0, 0)' : 'rgb(255, 255, 255)';
  $('.menu__item').css('color', color);

  let svg = ($('svg > path').css('fill') === 'rgb(255, 255, 255)') ? '#434546' : 'rgb(255, 255, 255)';
  $('svg > path').css('fill', svg);

})

$(function(){
	$(window).scroll(function(){
		if($(window).scrollTop() > 100) {
			$('#scroll_top').slideDown();
		} else {
			$('#scroll_top').slideUp();
		}
	});

	$('#scroll_top').on('click', function(){
		$('html, body').animate({scrollTop: 0}, 600);
		return false;
	});
});

});

